<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="상세정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="소방설비명"
                  name="fireFightingName"
                  v-model="fireFighting.fireFightingName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <c-text
                  disabled
                  :editable="editable"
                  label="장소"
                  name="locationName"
                  v-model="fireFighting.locationName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <c-select
                  :required="true"
                  :editable="editable"
                  codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="sopFireFightingTypeCd"
                  label="설비유형"
                  v-model="fireFighting.sopFireFightingTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2">
                <c-plant
                  :required="true"
                  :editable="editable"
                  :disabled="isOld"
                  type="edit"
                  name="plantCd"
                  v-model="fireFighting.plantCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-dept-multi 
                  :required="true"
                  :editable="editable"
                  :parentCheckDepts="fireFighting.deptCd" 
                  :plantCd="fireFighting.plantCd" 
                  label="담당부서" 
                  name="deptCd" 
                  v-model="fireFighting.deptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-multi-field 
                  :required="true"
                  :editable="editable"
                  label="담당자" 
                  name="managerId" 
                  v-model="fireFighting.managerId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <c-textarea
                  :editable="editable"
                  :rows="5"
                  label="비고(특이사항)" 
                  name="remarks" 
                  v-model="fireFighting.remarks" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <c-danger-area
                  :editable="editable"
                  :plantCd="fireFighting.plantCd"
                  label="관련 위험물저장소"
                  name="chmDangerMstId"
                  v-model="fireFighting.chmDangerMstId"
                ></c-danger-area>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="설치 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-text
                  :editable="editable"
                  label="설치번호"
                  name="manageNo"
                  v-model="fireFighting.manageNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-datepicker
                  :editable="editable"
                  label="설치일"
                  type="month"
                  default="today"
                  name="installDate"
                  v-model="fireFighting.installDate"
                />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-card title="상태 정보" class="cardClassDetailForm" topClass="topcolor-blue">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-select
                  :editable="editable"
                  :comboItems="disposeFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="disposeFlag"
                  label="폐기여부"
                  v-model="fireFighting.disposeFlag"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <c-datepicker
                  :required="fireFighting.disposeFlag==='Y'"
                  :editable="editable"
                  :disabled="fireFighting.disposeFlag==='N'"
                  label="폐기일"
                  name="disposeDate"
                  v-model="fireFighting.disposeDate"
                  />
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            flat
            :url="saveUrl"
            :isSubmit="isSave"
            :param="fireFighting"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'fireFightingInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',
      }),
    },
    fireFighting: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',  // 소방설비 일련번호
        plantCd: null,  // 사업장
        plantName: null,  // 사업장
        sopFireFightingTypeCd: null,  // 유형
        sopFireFightingTypeName: null,  // 유형
        manageNo: '',  // 설치번호
        fireFightingName: '',  // 소방설비명
        managerId: '',  // 담당자
        deptCd: '',  // 관리부서
        amount: '',  // 수량
        chmDangerMstId: '',  // 관련 위험물 저장소 일련번호
        remarks: '', // 비고(특이사항)
        installDate: '',  // 제조년월
        disposeFlag: 'N',  // 폐기여부
        disposeDate: '',  // 폐기일
        locationName: '',
        deleteFlag: '',  // 삭제여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        histroy: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      disposeFlagItems: [
        { code: 'N', codeName: '미폐기' },
        { code: 'Y', codeName: '폐기' },
      ],
      editable: true,
      isSave: false,
      saveUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.fft.fireFighting.update.url
      // code setting
      // list setting
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.fft.fireFighting.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.fft.fireFighting.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.fireFighting.regUserId = this.$store.getters.user.userId;
              this.fireFighting.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopFireFightingId', result.data)
      this.$emit('getDetail');
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>